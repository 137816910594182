import React from "react";
import { Route, Switch } from "react-router";
import Login from "../../Auth/Login";

const PublicLayout = () => {
  return (
    <>
      <Switch>
        <Route exact path="/">
          <Login />
        </Route>
        <Route exact path="/login">
          <Login />
        </Route>
      </Switch>
    </>
  );
};

export default PublicLayout;
