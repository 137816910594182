import isEmpty from "is-empty";
import moment from "moment";
import React, { useEffect, useState, Suspense } from "react";
import { useParams } from "react-router";
import { Col, Form, Row, Button, Table } from "react-bootstrap";
import { ConfirmModal } from "../Components/Modal/ConfirmModal";
import { CustomModal } from "../Components/Modal/CustomModal";
import { ToastMessage } from "../Components/Toast";

import { getDefaultMaxMin, setDefaultMaxMin } from "./Service";

import { allSeries } from "./Service";

import { useHistory } from "react-router";
import horse_json_data from "./eventlist.json";

export default function HorseRacingAddEvents() {
  let history = useHistory();
  const { eventId, matchId, matchName } = useParams();
  const [showToast, setShowToast] = useState(false);

  const [showEditBetModal, setShowEditBetModal] = useState(false);
  const [showAddEventModal, setAddEventModal] = useState(false);
  const [errorMessage, setErroreMessage] = useState("");
  const [editBetData, setEditBetData] = useState({});
  const [isBetLockStatus, setIsBetLockStatus] = useState(false);

  const handleCloseToast = () => setShowToast(false);
  const handleShowToast = () => setShowToast(true);

  const [toastMessage, setToastMessage] = useState("");
  const [allRunnerData, setAllRunnerData] = useState([]);
  const [errorMessageMax, setErrorMessageMax] = useState("");

  const handleCloseEditBetModal = () => {
    setEditBetData([]);
    setShowEditBetModal(false);
  };
  const handleCloseAddEventModal = () => setAddEventModal(false);
  const handleAddEventModal = () => setAddEventModal(true);

  const handleOpenEditBetModal = (id, type) => {
    setEditBetData((prevState) => ({
      ...prevState,
      matchId: id.matchId,
      maxStack: id.maxStack,
      minStack: id.minStack,
    }));
    debugger;
    setShowEditBetModal(true);
  };

  const handleSwitchChange = (e, data, type) => {
    debugger;
    console.log(data, "checkData");
    //setIsBetLockStatus(false);
    //setIsMatchBetLockStatus(false);
  };

  const onSubmitBetHandler = async () => {
    //setErroreMessage("Invalid input!");
    debugger;
    const { minStack, maxStack, matchId } = editBetData;
    if (parseInt(minStack) > parseInt(maxStack)) {
      setErroreMessage("Invalid input!");
      return;
    } else {
      setErroreMessage("");
    }

    handleShowToast();
    setToastMessage("Action updated successfully!");
    handleCloseEditBetModal();
  };

  const onSubmitAddEventHandler = async () => {
    setErroreMessage("Invalid input!");
    setAddEventModal();
  };

  const handleAddEvent = (e) => {
    const { name, value } = e.target;
    setErroreMessage("");
    setEditBetData((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handleBetEditChange = (e) => {
    const { name, value } = e.target;
    setErroreMessage("");
    console.log(name, value, "checkvAl");
    setEditBetData((prevState) => {
      if (name === "minStack") {
        if (value > prevState?.maxStack) {
          setErrorMessageMax("Min Stack can not be greater than max stack");
        } else {
          setErrorMessageMax("");
        }
      }
      if (name === "maxStack") {
        if (prevState?.minStack > value) {
          setErrorMessageMax("Min Stack can not be greater than max stack");
        } else {
          setErrorMessageMax("");
        }
      }
      return { ...prevState, [name]: value };
    });
  };

  const getAllEvents = async () => {
    //const res = await getAllRunner();
    let code = 200;
    if (code === 200) {
      //   onSubmitUserHandler(res.response);
      setAllRunnerData(horse_json_data);
      //setSportData(res.response);
    }
  };

  useEffect(() => {
    getAllEvents();
  }, []);

  return (
    <>
      {/* <header class="mb-3">
          <div className="d-grid gap-2">
            <Button 
              variant="primary"
              >
              Add Series
            </Button>
           
          </div>
        </header> */}

      <header class="mb-2">
        <Row>
          <Col>
            {
              <Button
                variant="primary"
                size="lg"
                onClick={() => handleAddEventModal()}
              >
                Add Event
              </Button>
            }
          </Col>
        </Row>
      </header>

      <div class="page-heading">
        <h3>Horse Racing Events</h3>
      </div>

      <Table striped bordered variant="dark">
        <thead>
          <tr>
            <th>Event Name</th>
            <th>Bet</th>

            <th>Bet Status</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {allRunnerData?.map((market) => (
            <tr key={market.mktId}>
              <td>
                {market.eventName}{" "}
                {moment(market?.startDate).format("Do MMM, h:mm: a")}
              </td>

              <td
                title="Edit"
                style={{ cursor: "pointer" }}
                onClick={() => handleOpenEditBetModal(market)}
              >
                <span>{market.minStack}</span> {" - "}{" "}
                <span>{market.maxStack}</span>
              </td>

              <td>
                {" "}
                <Form.Check
                  type="switch"
                  label={market.betLock ? "Bet Disabled" : "Bet Enabled"}
                  checked={market.betLock}
                  onChange={(e) =>
                    handleSwitchChange(e, market, "Market_BetStatus")
                  }
                />
              </td>
              <td>
                <Form.Check
                  type="switch"
                  label={market.enabled ? "Enable" : "Disable"}
                  onChange={(e) =>
                    handleSwitchChange(e, market, "Market_Enable")
                  }
                  checked={market.enabled}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <CustomModal
        show={showAddEventModal}
        handleClose={handleCloseAddEventModal}
        onSubmit={onSubmitAddEventHandler}
        title="Add Event"
        isError={errorMessage}
      >
        <Form>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Event Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Event Name"
              name="eventName"
              value={editBetData.eventName}
              onChange={handleBetEditChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Event Date</Form.Label>
            <Form.Control
              type="text"
              placeholder="Event Date"
              name="eventDate"
              value={editBetData.eventDate}
              onChange={handleBetEditChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Min Bet</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter min bet value"
              name="minStack"
              value={editBetData.minStack}
              onChange={handleBetEditChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Max Bet</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter max bet value"
              name="maxStack"
              value={editBetData.maxStack}
              onChange={handleBetEditChange}
            />
          </Form.Group>
        </Form>
        {errorMessage && <p className="text-danger">{errorMessage}</p>}
        {errorMessageMax && <p className="text-danger">{errorMessageMax}</p>}
      </CustomModal>

      <CustomModal
        show={showEditBetModal}
        handleClose={handleCloseEditBetModal}
        onSubmit={onSubmitBetHandler}
        title="Edit Event Bet"
        isError={errorMessage}
      >
        <Form>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Min Bet</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter min bet value"
              name="minStack"
              value={editBetData.minStack}
              onChange={handleBetEditChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Max Bet</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter max bet value"
              name="maxStack"
              value={editBetData.maxStack}
              onChange={handleBetEditChange}
            />
          </Form.Group>
        </Form>
        {errorMessage && <p className="text-danger">{errorMessage}</p>}
      </CustomModal>

      <ToastMessage
        show={showToast}
        close={handleCloseToast}
        message={toastMessage}
        type="Success"
        title=""
      />
    </>
  );
}
