import isEmpty from "is-empty";
import moment from "moment";
import React, { useEffect, useState, Suspense, useCallback } from "react";

import { Col, Form, Row, Button, Table } from "react-bootstrap";
import { ConfirmModal } from "../Components/Modal/ConfirmModal";
import { CustomModal } from "../Components/Modal/CustomModal";
import { ToastMessage } from "../Components/Toast";

import {
  createChildEventHAC,
  createMasterEvent,
  getDefaultMaxMin,
  getDefaultMaxMinHAC,
  setDefaultMaxMin,
  validateChildEventHAC,
  validateMasterEventsHAC,
} from "./Service";

import { useHistory } from "react-router";
import DatePicker from "react-datepicker";
import { useUser } from "../Context/UserProvider";
import { useToast } from "react-toastify";

export default function AddEventHAC({ getData, onClose, eventId }) {
  let history = useHistory();
  const toast = useToast();
  const { userData } = useUser();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [defaultData, setDefaultData] = useState({
    betLock: true,
    enabled: true,
  });
  const [startDate, setStartDate] = useState(new Date());
  const [error, setError] = useState("");
  const [isValidate, setIsValidate] = useState(true);
  const [errorMessageMax, setErrorMessageMax] = useState("");
  const [isEventAvailable, setIsEventAvailable] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDefaultData((prev) => {
      if (name === "minStack") {
        if (+value > prev?.maxStack) {
          setErrorMessageMax("Min Stack can not be greater than max stack");
        } else {
          setErrorMessageMax("");
        }
      }
      if (name === "maxStack") {
        if (prev?.minStack > +value) {
          setErrorMessageMax("Min Stack can not be greater than max stack");
        } else {
          setErrorMessageMax("");
        }
      }
      return { ...prev, [name]: value };
    });
    setError("");
  };

  const handleSwitchChange = (e, type) => {
    setDefaultData((prev) => ({ ...prev, [type]: e.target.checked }));
    setError("");
  };

  const handleCloseToast = () => setShowToast(false);
  const handleShowToast = () => setShowToast(true);

  const getDefaultMaxMinHandler = async () => {
    const res = await getDefaultMaxMinHAC();
    if (res.code === 200) {
      const { max, min } = res.response;
      setDefaultData((prev) => ({ ...prev, maxStack: max, minStack: min }));
    }
  };

  const createChildEventHACHandler = async () => {};

  const onSubmitHandler = async () => {
    const { maxStack, minStack, competitionsName, betLock, enabled } =
      defaultData;
    //startDate: moment(startDate).format("DD/MMM/YYYY 00:00:00") + " AM",
    //
    const payload = {
      active: true,
      betLock,
      createdBy: userData.username,
      enabled,
      eventName: competitionsName,
      masterEventId: eventId,
      maxStack,
      minStack,
      settled: null,
      startDate: moment(startDate).format("DD/MMM/YYYY hh:mm:ss A"),
    };

    const res = await createChildEventHAC(payload);
    console.log(res, "resData");
    if (res.code === 200) {
      handleShowToast();
      toast.success("Event created successfully");
      getData();
      onClose();
    }
    // handleShowToast();
    // setToastMessage("Value updated successfully");
    // const eventID = "32432";
    // history.push(`/dashboard/horse-racing/add-event/${eventID}`);

    // console.log(res, "resData");
  };

  useEffect(() => {
    getDefaultMaxMinHandler();
  }, []);

  const validateMasterEventsHACHandler = async (date) => {
    const res = await validateChildEventHAC(
      eventId,
      defaultData?.competitionsName,
      date.toISOString()
    );
    if (res.code === 200) {
      if (!res.response.available) {
        setIsEventAvailable(true);
      } else {
        setIsEventAvailable(false);
      }
    }
  };

  const handleDateChange = (date) => {
    setStartDate(date);
    validateMasterEventsHACHandler(date);
  };

  return (
    <>
      {/* <header class="mb-3">
          <div className="d-grid gap-2">
            <Button 
              variant="primary"
              >
              Add Series
            </Button>
           
          </div>
        </header> */}

      <div class="page-content">
        <div class="row" id="basic-table">
          <div class="col-12 p-0">
            <div
              class="card mb-0"
              style={{ backgroundColor: "transparent", border: "0" }}
            >
              <div class="card-content">
                <div class="card-body">
                  <div className="event_body series_value_set">
                    <Row>
                      <Col md={12}>
                        <Form>
                          <Form.Group className="mb-3" controlId="">
                            <Form.Label>Event Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter event name"
                              name="competitionsName"
                              value={defaultData?.competitionsName}
                              onChange={handleChange}
                            />
                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>Min Stack</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter min stack value"
                              name="minStack"
                              value={defaultData?.minStack}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Max Stack</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter max stack value"
                              name="maxStack"
                              value={defaultData?.maxStack}
                              onChange={handleChange}
                            />
                          </Form.Group>

                          <Form.Group className="mb-3">
                            <Form.Label>Start Date</Form.Label>
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => handleDateChange(date)}
                              showTimeSelect
                              dateFormat="MMMM d, yyyy h:mm aa"
                            />
                          </Form.Group>

                          <Form.Check
                            type="switch"
                            id="betLock"
                            label={
                              defaultData?.betLock
                                ? "Bet Unlocked"
                                : "Bet Locked "
                            }
                            onChange={(e) => handleSwitchChange(e, "betLock")}
                            checked={defaultData?.betLock}
                          />

                          <Form.Check
                            type="switch"
                            id="enable"
                            label={defaultData?.enabled ? "Enable " : "Disable"}
                            onChange={(e) => handleSwitchChange(e, "enabled")}
                            checked={defaultData?.enabled}
                          />

                          {error && <p className="text-danger">{error}</p>}
                          {errorMessageMax && (
                            <p className="text-danger">{errorMessageMax}</p>
                          )}

                          <Button
                            variant="primary"
                            onClick={onSubmitHandler}
                            className="mt-3 w-100"
                            disabled={!isEventAvailable || errorMessageMax}
                          >
                            Save
                          </Button>
                        </Form>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <ToastMessage
        show={showToast}
        close={handleCloseToast}
        message={toastMessage}
        type="Success"
        title=""
      /> */}
    </>
  );
}
