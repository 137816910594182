import React from "react";
import { Redirect, Switch } from "react-router-dom";
import AuthRoutes from "../routes/AuthRoutes";
import UnAuthRoutes from "../routes/UnAuthRoutes";
import PublicLayout from "../Components/layout/PublicLayout";
import DashboardLayout from "../Components/layout/DashboardLayout";

const Routes = () => {
  return (
    <Switch>
      <AuthRoutes path={"/dashboard"} component={DashboardLayout} />
      <UnAuthRoutes path={"/"} component={PublicLayout} />
      <Redirect to="/login" />
    </Switch>
  );
};

export default Routes;
