import React, { useEffect, useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";

import { ToastMessage } from "../../Components/Toast";
import { ConfirmModal } from "../../Components/Modal/ConfirmModal";
import { matchEnableDisabled, matchBetEnableDisabled } from "../Service";
import moment from "moment";
import { BASE_URL } from "../../utils/config";
import toast from "react-hot-toast";
import { AXIOS_INSTANCE } from "../../service";
export default function UpComingFixture() {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [viewFixtureList, setViewFixtureList] = useState([]);
  const [data, setData] = useState([]);
  const [selectedSport, setSelectedSport] = useState("cricket");
  const [confirmSeriesDetails, setConfirmSeriesDetails] = useState("");
  const [showConfrimModal, setShowConfirmModal] = useState(false);
  const [confirmMatchDetails, setConfirmMatchDetails] = useState("");
  const handleCloseToast = () => setShowToast(false);
  const handleShowToast = () => setShowToast(true);
  const handleCloseConfirmModal = () => setShowConfirmModal(false);
  const handleOpenConfirmModal = () => setShowConfirmModal(true);

  const fixtureList = async () => {
    try {
      const token = localStorage.getItem("token");
      const { data } = await AXIOS_INSTANCE.get(
        `${BASE_URL}/admin/upcoming-fixture/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setViewFixtureList(data?.response[selectedSport]);
    } catch (error) {
      console.error(error, "ERRORRRRR");
    }
  };

  const fixtureStatus = async (id, flag) => {
    try {
      const token = localStorage.getItem("token");
      const { data } = await AXIOS_INSTANCE.put(
        `${BASE_URL}/admin/upcoming-fixture`,
        {},
        {
          params: {
            eventId: id,
            sport: selectedSport.toUpperCase(),
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Status Updated Successfully");
      fixtureList();
      return data;
    } catch (err) {
      return err;
    }
  };

  /* const handleSwitchChange = (e, data, type) => {
    e.stopPropagation();
    console.log(type, "type");
    if (type == "MATCH_STATUS") {
      confirmStatusUpdate(data, "MATCH_STATUS");
    }
    if (type === "MATCH_BET_STATUS") {
      confirmStatusUpdate(data, "MATCH_BET_STATUS");
    }
    handleOpenConfirmModal();
  };

  const handleConfirm = async () => {
    const { isEnabled, eventId, betEnable, marketId, matchId, event, enabled } =
      confirmMatchDetails;
    let res;
    console.log(event, "event");
    if (event === "MATCH_STATUS") {
      res = await matchEnableDisabled(eventId, enabled);
    }
    if (event === "MATCH_BET_STATUS") {
      res = await matchBetEnableDisabled(matchId, betEnable);
    }

    setShowConfirmModal(false);
  };

  const confirmStatusUpdate = (data, type) => {
    if (type === "BET") {
      setConfirmSeriesDetails((prevState) => ({
        ...prevState,
        confirmValue: data.betLock ? "Enabled Bet" : "Disabled Bet",
        seriesName: data.competitionsName,
        seriesId: data.seriesId,
        betEnable: !data.betLock,
      }));
    } else if (type === "MATCH_BET_STATUS") {
      setConfirmMatchDetails((prevState) => ({
        ...prevState,
        confirmValue: data.enabled ? "Enabled Bet" : "Disabled Bet",
        matchName: data.eventName,
        matchId: data.id,
        betEnable: !data.betLock,
        event: type,
      }));
    } else if (type == "MATCH_STATUS") {
      setConfirmMatchDetails((prevState) => ({
        ...prevState,
        confirmValue: data.enabled ? "Enabled" : "Disabled",
        matchName: data.eventName,
        eventId: data.id,
        enabled: !data.enabled,
        event: type,
      }));
    } else if (type === "MARKET") {
      setConfirmMatchDetails((prevState) => ({
        ...prevState,
        confirmValue: data.enabled ? "Enabled Bet" : "Disabled Bet",
        matchName: data.eventName,
        marketId: data.mktId,
        betEnable: !data.betLock,
      }));
    } else if (type === "MARKET_ENABLE") {
      setConfirmSeriesDetails((prevState) => ({
        ...prevState,
        confirmValue: data.enabled ? "Enabled" : "Disabled",
        seriesName: data.competitionsName,
        marketId: data.mktId,
        isEnable: !data.enabled,
      }));
    } else {
      setConfirmSeriesDetails((prevState) => ({
        ...prevState,
        confirmValue: data.enabled ? "Enabled" : "Disabled",
        seriesName: data.competitionsName,
        seriesId: data.seriesId,
        isEnable: !data.enabled,
      }));
    }
  }; */

  useEffect(() => {
    fixtureList();
  }, [selectedSport]);

  return (
    <>
      <div className="page-heading">
        <h3>Upcoming Fixture</h3>
      </div>
      <div className="container">
        <div className="row">
          <div class="row">
            <div class="col-md-3 mb-4">
              <h6>Select Sports</h6>
              <fieldset class="form-group">
                <select
                  onChange={(e) => {
                    setSelectedSport(e.target.value);
                  }}
                  class="form-select"
                  id="basicSelect"
                  value={selectedSport}
                >
                  <option value={"cricket"}>Cricket</option>
                  <option value={"tennis"}>Tennis</option>
                  <option value={"football"}>Soccer</option>
                </select>
              </fieldset>
            </div>
          </div>
          <div className="col-12">
            {/* <a href="/dashboard/update-fixture">
              <button className="btn btn-primary right" href="/dashboard/add-banner">
                Add Fixture
              </button>
            </a> */}

            {/* This is for if data coming from backend */}

            {/* <table className="table table-image">
              <thead className="text">
                <tr>
                  <th scope="col">S. No.</th>
                  <th scope="col">ID</th>
                  <th scope="col">Name</th>
                  <th scope="col">Sport</th>
                  <th scope="col">Starts At</th>
                  <th scope="col">Active</th>
                </tr>
              </thead>
              <tbody className="text">
                {viewFixtureList?.length > 0 ? (
                  viewFixtureList?.map((row, index) => (
                    <tr key={index}>
                      <th>{index + 1}</th>
                      <th>{row.id}</th>
                      <th>{row.eventName}</th>
                      <th>{row.sport}</th>
                      <th>{row.startTime}</th>
                      <th>{row.active}</th>
                      <th>
                        <button className="btn btn-primary right">Edit</button>
                        <button type="switch" onClick={() => fixtureStatus(row.id)}/>
                        </th>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" className="text-center">No Data Available</td>
                  </tr>
                )}
              </tbody>
            </table> */}

            <table className="table table-image">
              <thead className="text">
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Event Name</th>
                  <th scope="col">Starts At</th>
                  <th scope="col">Active</th>
                </tr>
              </thead>
              <tbody className="text">
                {viewFixtureList?.map((event, index) => (
                  <tr>
                    <th>{event.id}</th>
                    <th>{event.eventName}</th>
                    <th>
                      {moment(event.startTime).format("DD/MM/YYYY hh:mm")}
                    </th>
                    <th>
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        onChange={(e) => {
                          fixtureStatus(event.id, e.target.checked);
                        }}
                        defaultChecked={event.active}
                      />
                    </th>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
