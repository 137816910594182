import isEmpty from "is-empty";
import React, { useEffect, useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";

import { ToastMessage } from "../Components/Toast";
import { getDefaultMaxMin, setDefaultMaxMin } from "./Service";

export default function SeriesManagement() {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [defaultData, setDefaultData] = useState({});
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDefaultData((prev) => ({ ...prev, [name]: value }));
    setError("");
  };

  const handleCloseToast = () => setShowToast(false);
  const handleShowToast = () => setShowToast(true);

  const getDefaultMaxMinHandler = async () => {
    const res = await getDefaultMaxMin();
    if (res.code === 200) {
      const { max, min } = res.response;
      setDefaultData({
        max,
        min,
      });
    }

    console.log(res, "resData");
  };

  const onSubmitHandler = async () => {
    const { max, min } = defaultData;
    if (min > max) {
      return setError("Invalid input value");
    }

    const res = await setDefaultMaxMin(max, min);
    handleShowToast();
    setToastMessage("Value updated successfully");
    console.log(res, "resData");
  };

  useEffect(() => {
    getDefaultMaxMinHandler();
  }, []);

  return (
    <>
      {/* <header class="mb-3">
        <a href="#" class="burger-btn d-block d-xl-none">
          <i class="bi bi-justify fs-3"></i>
        </a>
      </header> */}

      <div class="page-heading">
        <h3>Series Management</h3>
      </div>
      <div class="page-content">
        <div class="row" id="basic-table">
          <div class="col-12">
            <div class="card">
              <div class="card-content">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-3 mb-4">
                      <h6>Select Sports</h6>
                      <fieldset class="form-group">
                        <select class="form-select" id="basicSelect">
                          <option>Cricket</option>
                          <option>Tennis</option>
                          <option>Soccer</option>
                        </select>
                      </fieldset>
                    </div>
                  </div>
                  <div className="event_body series_value_set">
                    <Row>
                      <Col md={6}>
                        <Form>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>Min Bet</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter min bet value"
                              name="min"
                              value={defaultData?.min}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Max Bet</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter max bet value"
                              name="max"
                              value={defaultData?.max}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          {error && <p className="text-danger">{error}</p>}
                          <Button variant="primary" onClick={onSubmitHandler}>
                            Save
                          </Button>
                        </Form>
                      </Col>
                      <Col md={6}></Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastMessage
        show={showToast}
        close={handleCloseToast}
        message={toastMessage}
        type="Success"
        title=""
      />
    </>
  );
}
