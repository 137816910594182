import { PORT } from "../utils/port";
import { AXIOS_INSTANCE } from "../service";
export const sportsActiovationInfo = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${process.env.REACT_APP_API_URL}:${PORT}/aam/api/accmanage/activationInfo`
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const sportsActiovationInfoChange = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${process.env.REACT_APP_API_URL}:${PORT}/aam/api/accmanage/activationInfo`,
      payload
    );
    return data;
  } catch (err) {
    return err;
  }
};
