import React, { useEffect, useState, useRef, useNavigate } from "react";

import "../style.css";

import { ToastContainer } from "react-toastify";
import toast from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import "./banner.css";
import { BASE_URL } from "../../utils/config";
import { AXIOS_INSTANCE } from "../../service";

export default function AddBanner() {
  const uploadFileRef = useRef();
  const [banner, setBanner] = useState();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [file, setFile] = useState();
  const [priority, setPriority] = useState("");

  const uploadFileAPI = async () => {
    var formData = new FormData();
    formData.append("file", banner[0]);
    try {
      const { data } = await AXIOS_INSTANCE.post(
        `${BASE_URL}/auth/upload-doc`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return data?.response;
    } catch (error) {
      return error;
    }
  };

  const addNewBanner = async () => {
    if (!banner) {
      toast.error("Please upload banner");
      return;
    }
    if (!fromDate) {
      toast.error("Please select from date");
      return;
    }
    if (!toDate) {
      toast.error("Please select to date");
      return;
    }
    if (!priority) {
      toast.error("Please select priority");
      return;
    }
    let bannerUrl = await uploadFileAPI();
    let payload = {
      fromDate: new Date(fromDate),
      toDate: new Date(toDate),
      isActive: true,
      bannerPriority: priority,
      bannerUrl: bannerUrl,
    };

    try {
      const {
        data: { code },
      } = await AXIOS_INSTANCE.post(
        `${BASE_URL}/admin/dashboard-banner`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (code === 200) {
        window.location.href = "/dashboard/banner-management";
      }
    } catch (error) {
      console.log(error);
    }
  };

  const limiter = (input) => {
    if (input.value < 0) input.value = 0;
    if (input.value > 10) input.value = 10;
  };

  /*  const handleAddBanner = async () => {
    if (banner) {
      const { statusCode, message ,code} = await addNewBannerAPI({
        fromDate: new Date(fromDate),
        toDate: new Date(toDate),
        isActive: true,
        bannerPriority: priority,
      });
    }
    
  }; */

  const Error = () => toast.error("error!");
  const Success = () => toast.success("Banner added successfully!");

  return (
    <>
      <ToastContainer />

      <div style={{ height: "90vh" }}>
        <input
          type="file"
          onChange={(e) => {
            setBanner(e.target.files);
          }}
          ref={uploadFileRef}
          className="hidden"
        />
        {banner ? (
          <img
            className="banner-img"
            onClick={() => uploadFileRef.current.click()}
            src={URL.createObjectURL(banner[0])}
          />
        ) : (
          <div
            className="upload-box"
            onClick={() => uploadFileRef.current.click()}
          >
            <p style={{ color: "#6B7280", fontSize: "bold" }}>
              UPLOAD BANNER HERE*
            </p>
          </div>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <dev style={{ position: "relative", marginBottom: "0.75rem" }}>
            <label for="floatingInput" style={{ color: "lightgray" }}>
              Priority
            </label>
            <input
              type="number"
              placeholder="Set priority"
              onChange={(e) => setPriority(e.target.value)}
            />
          </dev>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ position: "relative", marginBottom: "0.75rem" }}>
            <label for="floatingInput" style={{ color: "lightgray" }}>
              Valid from date
            </label>
            <input
              onChange={(e) => setFromDate(e.target.value)}
              type="date"
              className="date-box"
              placeholder="Select a date"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ position: "relative", marginBottom: "0.75rem" }}>
            <label for="floatingInput" style={{ color: "lightgray" }}>
              Valid Upto date
            </label>
            <input
              onChange={(e) => setToDate(e.target.value)}
              type="date"
              className="date-box"
              placeholder="Select a date"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            verticalAlign: "middle",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <button
            onClick={addNewBanner}
            style={{
              paddingTop: "0.5rem",
              paddingBottom: "0.5rem",
              marginTop: "1rem",
              marginBottom: "1rem",
              backgroundColor: "#93C5FD",
              borderRadius: "0.375rem",
            }}
          >
            Add Banner
          </button>
        </div>
      </div>
    </>
  );
}
