import isEmpty from "is-empty";
import moment from "moment";
import React, { useEffect, useState, Suspense, useCallback } from "react";
import {
  Accordion,
  FloatingLabel,
  Form,
  Table,
  Button,
  Row,
  Col,
  Tabs,
  Tab,
} from "react-bootstrap";
import { ConfirmModal } from "../Components/Modal/ConfirmModal";
import { CustomModal } from "../Components/Modal/CustomModal";
import { ToastMessage } from "../Components/Toast";
import horse_json_data from "./horse_racing_series.json";

import {
  allMarkets,
  allSeries,
  allSeriesAdminHAC,
  childEventBetEnablingHAC,
  childEventEnablingHAC,
  childEventSetMaxMinBetHAC,
  marketBetEnableDisabled,
  marketEnableDisabled,
  masterEventBetEnablingHAC,
  masterEventEnablingHAC,
  masterEventSetMaxMinBetHAC,
  matchBetEnableDisabled,
  matchEnableDisabled,
  seriesBetEnableDisabled,
  seriesEnableDisabled,
  setMarketMaxMinBet,
  setMatchMaxMinBet,
  setSeriesMaxMinBet,
  settleMarket,
} from "./Service";
import AllMarketDataComponent from "./AllMarketData";
import { useHistory } from "react-router";
import HorseRacingAddSeries from "./HorseRacingAddSeries";

const marketNameTemp = "Match Odds";

export default function EventManagementHorseRacing() {
  let history = useHistory();
  const [sportData, setSportData] = useState([]);
  const [allSeriesAdminData, setSeriesAdminData] = useState([]);
  const [showConfrimModal, setShowConfirmModal] = useState(false);
  const [showConfrimMatchModal, setShowConfirmMatchModal] = useState(false);
  const [confirmSeriesDetails, setConfirmSeriesDetails] = useState("");
  const [confirmMatchDetails, setConfirmMatchDetails] = useState("");
  const [allMarketData, setAllMarketData] = useState([]);
  const [showEditBetModal, setShowEditBetModal] = useState(false);
  const [editBetData, setEditBetData] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [isBetLockStatus, setIsBetLockStatus] = useState(false);
  const [isEnableLockStatus, setIsEnableLockStatus] = useState(false);
  const [isChildBetLockStatus, setIsChildBetLockStatus] = useState(false);
  const [isChildEnableLockStatus, setChildIsEnableLockStatus] = useState(false);
  const [isMatchBetLockStatus, setIsMatchBetLockStatus] = useState(false);
  const [isMarketBetLockStatus, setIsMarketBetLockStatus] = useState(false);
  const [isMarketEnableStatus, setIsMarketEnableStatus] = useState(false);
  const [isSeriesSetPrice, setIsSeriesSetPrice] = useState(false);
  const [isMatchSetPrice, setIsMatchSetPrice] = useState(false);
  const [errorMessage, setErroreMessage] = useState("");
  const [showMarketSettelModal, setShowMarketSettelModal] = useState(false);
  const [settelmentData, setSettelmentData] = useState({});
  const [isSessionPassed, setIsSessionPassed] = useState(false);
  const [winnerData, setWinnerData] = useState({});
  const [marketSettlmentType, setMarketSettlmentType] = useState("");
  const [matchRestult, setMatchResult] = useState("");
  const [selectedMatchId, setSelectedMatchId] = useState("");
  const [showAddCompitionModal, setShowAddCompitionModal] = useState(false);
  const [isMasterSetPrice, setIsMasterSetPrice] = useState(false);
  const [isChildSetPrice, setIsChildSetPrice] = useState(false);
  const [key, setKey] = useState("unsettled");

  const handleCloseMarketSettelModal = () => setShowMarketSettelModal(false);
  const handleOpenMarketSettelModal = (data, eventId, type, matchData) => {
    setShowMarketSettelModal(true);
    console.log(data, "checkData");
    setSettelmentData((prev) => ({ ...prev, ...data, eventId }));
    setMarketSettlmentType(type);

    setSelectedMatchId(matchData.matchId);
  };

  const handleCloseConfirmModal = () => setShowConfirmModal(false);
  const handleOpenConfirmModal = () => setShowConfirmModal(true);
  const handleCloseConfirmMatchModal = () => setShowConfirmMatchModal(false);
  const handleOpenConfirmMatachModal = () => setShowConfirmMatchModal(true);
  const handleCloseEditBetModal = () => setShowEditBetModal(false);
  const handleOpenEditBetModal = (id, type) => {
    if (type === "MASTER") {
      setIsMasterSetPrice(true);
      setIsChildSetPrice(false);
      setEditBetData((prevState) => ({ ...prevState, eventId: id }));
    } else if (type === "CHILD") {
      setIsChildSetPrice(true);
      setIsMasterSetPrice(false);
      setEditBetData((prevState) => ({ ...prevState, eventId: id }));
    }

    setShowEditBetModal(true);
  };

  const handleCloseToast = () => setShowToast(false);
  const handleShowToast = () => setShowToast(true);
  const handleCloseAddCompitionModal = () => setShowAddCompitionModal(false);
  const handleShowAddCompitionModal = () => setShowAddCompitionModal(true);

  const getAllSeriesAdminHAC = useCallback(async () => {
    const res = await allSeriesAdminHAC(key === "settled" ? true : false);
    console.log(res, "resDataR");
    if (res.code === 200) {
      //   onSubmitUserHandler(res.response);
      //       setUserRolesetSportData(horse_json_data);
      //setSportData(res.response);
      setSeriesAdminData(res.response);
    }
  }, [key]);

  useEffect(() => {
    getAllSeriesAdminHAC();
  }, [getAllSeriesAdminHAC]);

  const confirmStatusUpdate = (data, type) => {
    console.log(data, "checkStatus");
    if (type === "BET") {
      setConfirmSeriesDetails((prevState) => ({
        ...prevState,
        confirmValue: data.betLock ? "Enabled Bet" : "Disabled Bet",
        seriesName: data.competitionsName,
        eventId: data.id,
        betEnable: !data.betLock,
      }));
    } else if (type === "ENABLE") {
      setConfirmSeriesDetails((prevState) => ({
        ...prevState,
        confirmValue: data.enabled ? "Enabled" : "Disabled",
        seriesName: data.competitionsName,
        eventId: data.id,
        isEnable: !data.enabled,
      }));
    } else if (type === "CHILD_BET") {
      setConfirmSeriesDetails((prevState) => ({
        ...prevState,
        confirmValue: data.betLock ? "Enabled Bet" : "Disabled Bet",
        seriesName: data.competitionsName,
        eventId: data.id,
        betEnable: !data.betLock,
      }));
    } else if (type === "CHILD_ENABLE") {
      setConfirmSeriesDetails((prevState) => ({
        ...prevState,
        confirmValue: data.betLock ? "Enabled" : "Disabled",
        seriesName: data.competitionsName,
        eventId: data.id,
        isEnable: !data.enabled,
      }));
    }
  };

  const handleSwitchChange = (e, data, type) => {
    e.stopPropagation();
    console.log(data, "checkData");

    if (type === "Master_BetStatus") {
      handleOpenConfirmModal();
      confirmStatusUpdate(data, "BET");
      setIsBetLockStatus(true);
      setIsEnableLockStatus(false);
      setChildIsEnableLockStatus(false);
      setIsChildBetLockStatus(false);
    } else if (type === "Master_Enable") {
      setIsBetLockStatus(false);
      setIsEnableLockStatus(true);
      setIsChildBetLockStatus(false);
      setChildIsEnableLockStatus(false);
      handleOpenConfirmModal();
      confirmStatusUpdate(data, "ENABLE");
    } else if (type === "Child_BetStatus") {
      handleOpenConfirmModal();
      confirmStatusUpdate(data, "CHILD_BET");
      setIsChildBetLockStatus(true);
      setIsBetLockStatus(false);
      setIsEnableLockStatus(false);
      setChildIsEnableLockStatus(false);
    } else if (type === "Child_enable") {
      handleOpenConfirmModal();
      confirmStatusUpdate(data, "CHILD_ENABLE");
      setIsChildBetLockStatus(false);
      setIsBetLockStatus(false);
      setIsEnableLockStatus(false);
      setChildIsEnableLockStatus(true);
    }
  };
  const handleSwitchMatchChange = (e, data) => {
    e.stopPropagation();
    console.log(data, "checkData");
    handleOpenConfirmMatachModal();
    if (data.enabled) {
      setConfirmMatchDetails((prevState) => ({
        ...prevState,
        confirmValue: "Enabled",
        matchName: data.eventName,
        matchId: data.matchId,
        isEnabled: data.enabled,
      }));
    } else {
      setConfirmMatchDetails((prevState) => ({
        ...prevState,
        confirmValue: "Disabled",
        matchName: data.eventName,
        matchId: data.matchId,
        isEnabled: !data.enabled,
      }));
    }
  };

  const seriesEnableDisabledHandler = async () => {
    const { eventId, betEnable, isEnable } = confirmSeriesDetails;

    let res;
    if (isBetLockStatus) {
      res = await masterEventBetEnablingHAC(eventId, betEnable);
    } else if (isEnableLockStatus) {
      res = await masterEventEnablingHAC(eventId, isEnable);
    } else if (isChildBetLockStatus) {
      res = await childEventBetEnablingHAC(eventId, betEnable);
    } else if (isChildEnableLockStatus) {
      res = await childEventEnablingHAC(eventId, isEnable);
    }

    if (res.code === 200) {
      getAllSeriesAdminHAC();
      handleCloseConfirmModal();
    }

    console.log(res, "resConfirm");
  };
  const matchEnableDisabledHandler = async () => {
    const { isEnabled, matchId, betEnable, marketId } = confirmMatchDetails;

    let res;
    if (isMatchBetLockStatus) {
      res = await matchBetEnableDisabled(matchId, betEnable);
    } else if (isMarketBetLockStatus) {
      res = await marketBetEnableDisabled(marketId, betEnable);
    } else {
      res = await matchEnableDisabled(matchId, isEnabled);
    }

    getAllSeriesAdminHAC();
    handleCloseConfirmMatchModal();

    console.log(res, "resConfirm");
  };

  const allMarketsHandler = async (id) => {
    const res = await allMarkets(id);

    if (res.code === 200) {
      setAllMarketData(res.response);
    }

    console.log(res, "resData");
  };

  const handleMarketEditChange = (e) => {
    const { name, value } = e.target;
    setErroreMessage("");

    setEditBetData((prevState) => {
      console.log(prevState, "checkPRev");
      return { ...prevState, [name]: value };
    });
  };

  const onSubmitBetHandler = async () => {
    const { maxMarket, minMarket, marketId, eventId, matchId } = editBetData;
    if (parseInt(minMarket) > parseInt(maxMarket)) {
      setErroreMessage("Invalid input!");
      return;
    } else {
      setErroreMessage("");
    }
    let res;

    if (isMasterSetPrice) {
      res = await masterEventSetMaxMinBetHAC(maxMarket, minMarket, eventId);
    } else if (isChildSetPrice) {
      res = await childEventSetMaxMinBetHAC(maxMarket, minMarket, eventId);
    }

    if (res.code === 200) {
      getAllSeriesAdminHAC();
      handleShowToast();
      setToastMessage("Action updated successfully!");
      handleCloseEditBetModal();
    }

    console.log(res, "resStatus");
  };

  const handleSwitchSettelChange = (e) => {
    e.stopPropagation();

    setIsSessionPassed(e.target.checked);
  };

  const handleChangeSettel = (e) => {
    const { name, value } = e.target;
    setSettelmentData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangeWinner = (e) => {
    const { value } = e.target;
    console.log(value);
    if (value === "abandoned" || value === "tie") {
      return setMatchResult(value);
    } else {
      setMatchResult("");
    }
    const selectionId = value.split("-")[0];
    const winnerName = value.split("-")[1];
    setWinnerData((prev) => ({
      ...prev,
      selectionId: selectionId,
      winnerName,
    }));
  };

  const settleMarketHandler = async () => {
    const { marketName, eventId, marketId, comment } = settelmentData;
    const { selectionId, winnerName } = winnerData;

    //settle or rollback

    const payload = {
      eventId,
      marketId,
      marketName,
      settlementComment: comment,
      winnerName: winnerName,
      winnerSelectionId: selectionId,
      eventType: marketSettlmentType,
    };
    if (!isEmpty(matchRestult)) {
      payload[matchRestult] = true;
      delete payload.winnerName;
    }

    if (!marketName.includes(marketNameTemp)) {
      payload.isSessionPassed = isSessionPassed;
      delete payload.winnerName;
      delete payload.winnerSelectionId;
    }

    const res = await settleMarket(payload);

    console.log(res, "resData");
    handleCloseMarketSettelModal();
    handleShowToast();
    setToastMessage(res.message);
    setSettelmentData({});
    setSettelmentData({});
    setIsSessionPassed(false);
    //selectedMatchId
    allMarketsHandler(selectedMatchId);
  };

  console.log(allSeriesAdminData, "allSeriesAdminData");

  return (
    <>
      <Button
        variant="primary"
        onClick={handleShowAddCompitionModal}
        className="mb-3"
      >
        Add Competition
      </Button>
      <hr />

      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="settled" title="Settled">
          {allSeriesAdminData.map((c, i) => (
            <Accordion defaultActiveKey={0} key={c.id}>
              <Accordion.Item eventKey={i}>
                <Accordion.Header>
                  <h6
                    onClick={(e) => {
                      e.stopPropagation();
                      window.location.href = `/dashboard/horse-racing/competition-detail/${c.competitionsName}/${c.id}`;
                    }}
                  >
                    {" "}
                    {c.competitionsName}
                  </h6>

                  <div className="header_wrap">
                    <h6
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenEditBetModal(c.id, "MASTER");
                      }}
                    >
                      {c.minStack ? c.minStack : "0"} -{" "}
                      {c.maxStack ? c.maxStack : "0"}
                    </h6>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label={c.betLock ? "Bet Locked" : "Bet Unlocked"}
                      checked={c.betLock}
                      onChange={(e) =>
                        handleSwitchChange(e, c, "Master_BetStatus")
                      }
                      onClick={(e) => e.stopPropagation()}
                    />
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label={c.enabled ? "Enabled" : "Disabled"}
                      checked={c.enabled}
                      onChange={(e) =>
                        handleSwitchChange(e, c, "Master_Enable")
                      }
                      onClick={(e) => e.stopPropagation()}
                    />
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  {c.hacChildEvents.map((match, index) => (
                    <>
                      <Accordion key={match.id}>
                        <Accordion.Item eventKey={index}>
                          <Accordion.Header
                            onClick={() => {
                              // allMarketsHandler(match.matchId)
                              history.push(
                                `/dashboard/all-market/horse-racing/${match.eventName}/${match.id}`
                              );
                            }}
                          >
                            {match.eventName} -{" "}
                            {moment(match?.startDate).format("Do MMM, h:mm: a")}
                            <div className="header_wrap">
                              <h6
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleOpenEditBetModal(c.id, "CHILD");
                                }}
                              >
                                {match.minStack ? match.minStack : "0"} -{" "}
                                {match.maxStack ? match.maxStack : "0"}
                              </h6>
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                label={
                                  match.betLock ? "Bet Locked" : "Bet Unlocked"
                                }
                                checked={match.betLock}
                                onChange={(e) =>
                                  handleSwitchChange(
                                    e,
                                    match,
                                    "Child_BetStatus"
                                  )
                                }
                                onClick={(e) => e.stopPropagation()}
                              />
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                label={match.enabled ? "Enabled" : "Disabled"}
                                checked={match.enabled}
                                onChange={(e) =>
                                  handleSwitchChange(e, match, "Child_enable")
                                }
                                onClick={(e) => e.stopPropagation()}
                              />
                            </div>
                          </Accordion.Header>
                        </Accordion.Item>
                      </Accordion>
                    </>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))}
        </Tab>
        <Tab eventKey="unsettled" title="Unsettled">
          {allSeriesAdminData.map((c, i) => (
            <Accordion defaultActiveKey={0} key={c.id}>
              <Accordion.Item eventKey={i}>
                <Accordion.Header>
                  <h6
                    onClick={(e) => {
                      e.stopPropagation();
                      window.location.href = `/dashboard/horse-racing/competition-detail/${c.competitionsName}/${c.id}`;
                    }}
                  >
                    {" "}
                    {c.competitionsName}
                  </h6>

                  <div className="header_wrap">
                    <h6
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenEditBetModal(c.id, "MASTER");
                      }}
                    >
                      {c.minStack ? c.minStack : "0"} -{" "}
                      {c.maxStack ? c.maxStack : "0"}
                    </h6>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label={c.betLock ? "Bet Locked" : "Bet Unlocked"}
                      checked={c.betLock}
                      onChange={(e) =>
                        handleSwitchChange(e, c, "Master_BetStatus")
                      }
                      onClick={(e) => e.stopPropagation()}
                    />
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label={c.enabled ? "Enabled" : "Disabled"}
                      checked={c.enabled}
                      onChange={(e) =>
                        handleSwitchChange(e, c, "Master_Enable")
                      }
                      onClick={(e) => e.stopPropagation()}
                    />
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  {c.hacChildEvents.map((match, index) => (
                    <>
                      <Accordion key={match.id}>
                        <Accordion.Item eventKey={index}>
                          <Accordion.Header
                            onClick={() => {
                              // allMarketsHandler(match.matchId)
                              history.push(
                                `/dashboard/all-market/horse-racing/${match.eventName}/${match.id}`
                              );
                            }}
                          >
                            {match.eventName} -{" "}
                            {moment(match?.startDate).format("Do MMM, h:mm: a")}
                            <div className="header_wrap">
                              <h6
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleOpenEditBetModal(c.id, "CHILD");
                                }}
                              >
                                {match.minStack ? match.minStack : "0"} -{" "}
                                {match.maxStack ? match.maxStack : "0"}
                              </h6>
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                label={
                                  match.betLock ? "Bet Locked" : "Bet Unlocked"
                                }
                                checked={match.betLock}
                                onChange={(e) =>
                                  handleSwitchChange(
                                    e,
                                    match,
                                    "Child_BetStatus"
                                  )
                                }
                                onClick={(e) => e.stopPropagation()}
                              />
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                label={match.enabled ? "Enabled" : "Disabled"}
                                checked={match.enabled}
                                onChange={(e) =>
                                  handleSwitchChange(e, match, "Child_enable")
                                }
                                onClick={(e) => e.stopPropagation()}
                              />
                            </div>
                          </Accordion.Header>
                        </Accordion.Item>
                      </Accordion>
                    </>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))}
        </Tab>
      </Tabs>

      <ConfirmModal
        show={showConfrimModal}
        handleClose={handleCloseConfirmModal}
        handleConfirm={seriesEnableDisabledHandler}
      >
        <h5>
          Are you sure you want to{" "}
          <strong>{confirmSeriesDetails?.confirmValue}</strong> this{" "}
          <strong>{confirmSeriesDetails?.seriesName}</strong>?
        </h5>
      </ConfirmModal>
      <ConfirmModal
        show={showConfrimMatchModal}
        handleClose={handleCloseConfirmMatchModal}
        handleConfirm={matchEnableDisabledHandler}
      >
        <h5>
          Are you sure you want to{" "}
          <strong>{confirmMatchDetails?.confirmValue}</strong> this{" "}
          <strong>{confirmMatchDetails?.matchName}</strong>?
        </h5>
      </ConfirmModal>
      <CustomModal
        show={showEditBetModal}
        handleClose={handleCloseEditBetModal}
        onSubmit={onSubmitBetHandler}
        title="Edit Match Bet"
        isError={errorMessage}
      >
        <Form>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Min Bet</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter min bet value"
              name="minMarket"
              value={editBetData?.minMarket}
              onChange={handleMarketEditChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Max Bet</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter max bet value"
              name="maxMarket"
              value={editBetData?.maxMarket}
              onChange={handleMarketEditChange}
            />
          </Form.Group>
        </Form>
        {errorMessage && <p className="text-danger">{errorMessage}</p>}
      </CustomModal>
      <CustomModal
        show={showMarketSettelModal}
        handleClose={handleCloseMarketSettelModal}
        onSubmit={settleMarketHandler}
        title="Market Settelment"
        isError={errorMessage}
        settelmentData={settelmentData}
        fromSettel
        isRollback={marketSettlmentType === "rollback"}
      >
        {marketSettlmentType !== "rollback" ? (
          <Form>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              {settelmentData?.marketName
                ?.toLowerCase()
                .includes(marketNameTemp.toLowerCase()) ? (
                <FloatingLabel controlId="floatingSelect" label="Select Winner">
                  <Form.Select
                    aria-label="Floating label select example"
                    onChange={handleChangeWinner}
                  >
                    <option>Please select a winner</option>
                    {settelmentData?.subMarketInfo?.map((item) => (
                      <option
                        value={`${item.selectionId}-${item.runnerName}`}
                        key={item.selectionId}
                      >
                        {item.runnerName}
                      </option>
                    ))}
                    <option value="abandoned">Abandoned</option>
                    <option value="tie">Tie</option>
                  </Form.Select>
                </FloatingLabel>
              ) : (
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label={"Session Passed"}
                  onChange={(e) => handleSwitchSettelChange(e)}
                />
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Comment</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter comment"
                name="comment"
                value={settelmentData?.comment}
                onChange={handleChangeSettel}
              />
            </Form.Group>
            {errorMessage && <p className="text-danger">{errorMessage}</p>}
          </Form>
        ) : (
          <h5>Are you sure you want to rollback</h5>
        )}
      </CustomModal>
      <ToastMessage
        show={showToast}
        close={handleCloseToast}
        message={toastMessage}
        type="Success"
        title=""
      />

      <CustomModal
        show={showAddCompitionModal}
        handleClose={handleCloseAddCompitionModal}
        title="Add Competition"
        isError={errorMessage}
        noFooter
      >
        <HorseRacingAddSeries />
      </CustomModal>
    </>
  );
}
