import React from "react";
import { Modal, Button } from "react-bootstrap";
import isEmpty from "is-empty";

/**
 * @author
 * @function CustomModal
 **/

export const CustomModal = (props) => {
  const {
    show,
    handleClose,
    onSubmit,
    title,
    isError,
    fromSettel,
    settelmentData,
    isRollback,
    noFooter,
  } = props;
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
        {!noFooter && (
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={onSubmit}
              disabled={
                isError
                  ? true
                  : fromSettel && isEmpty(settelmentData.comment) && !isRollback
                  ? true
                  : false
              }
            >
              {isRollback ? "Yes, Roll Back" : "Submit"}
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};
