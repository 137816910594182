import React, { createContext, useContext, useState } from "react";

const AllMarketContext = createContext();

export function useAllMarket() {
  return useContext(AllMarketContext);
}

export default function AllMarketProvider({ children }) {
  const [allMarketData, setAllMarketData] = useState([]);

  const onSubmitAllMarketData = (data) => {
    setAllMarketData(data);
  };

  const values = { allMarketData, onSubmitAllMarketData };
  return (
    <AllMarketContext.Provider value={values}>
      {children}
    </AllMarketContext.Provider>
  );
}
