import isEmpty from "is-empty";
import React, { useCallback, useEffect, useState } from "react";
import { FloatingLabel, Form, Table, Row, Col, Button } from "react-bootstrap";
import { useParams } from "react-router";
import { ConfirmModal } from "../Components/Modal/ConfirmModal";
import { CustomModal } from "../Components/Modal/CustomModal";
import { ToastMessage } from "../Components/Toast";
import Pagination from "react-js-pagination";
import axios from "axios";
import horse_json_data from "./runnerllisting.json";
import { useHistory } from "react-router";
import {
  addRunnerHAC,
  allMarkets,
  childEventBetEnablingHAC,
  childEventInplayHAC,
  getChildEventByIdHAC,
  settleMarketHAC,
  updateRunnerHAC,
  validateRunnerHAC,
} from "./Service";
import { useAllMarket } from "../Context/AllMarketProvider";

import runnerllisting from "./runnerllisting.json";
import { useUser } from "../Context/UserProvider";
import { useToast } from "react-toastify";

export default function AllMarketDataHorseRacingComponent(props) {
  let history = useHistory();
  const toast = useToast();
  const { userData } = useUser();
  const { eventId, matchName } = useParams();
  const [showToast, setShowToast] = useState(false);
  const [showConfrimMatchModal, setShowConfirmMatchModal] = useState(false);
  const [confirmSeriesDetails, setConfirmSeriesDetails] = useState("");
  const [confirmMatchDetails, setConfirmMatchDetails] = useState("");
  const [addRunnerModal, setAddRunnerModal] = useState(false);
  const [showEditRunnerModal, setShowEditRunnerModal] = useState(false);
  const [errorMessage, setErroreMessage] = useState("");
  const [editRunnerData, setEditRunnerData] = useState({
    back1: 0,
    back2: 0,
    back3: 0,
    lay1: 0,
    lay2: 0,
    lay3: 0,
    horseName: "",
    jokeyName: "",
    jokeyNumber: "",
  });
  const [isBetLockStatus, setIsBetLockStatus] = useState(false);
  const [marketDataOfEvent, setMarketDataOfEvent] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [message, setMessage] = useState("");
  const [showConfrimModal, setShowConfirmModal] = useState(false);
  const [HACChildData, setHACChildData] = useState({});
  const [showConfrimInPlayModal, setShowConfrimInPlayModal] = useState(false);
  const [isInPlay, setIsInPlay] = useState(false);
  const [settelmentData, setSettelmentData] = useState({});
  const [marketSettlmentType, setMarketSettlmentType] = useState("");
  const [showMarketSettelModal, setShowMarketSettelModal] = useState(false);
  const [matchRestult, setMatchResult] = useState("");
  const [winnerData, setWinnerData] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [isInvalidValue, setIsInvalidValue] = useState(false);
  const [selectedSelectValue, setSelectedSelectValue] = useState("");
  const [selectedInputValue, setSelectedInputValue] = useState("");

  const handleCloseConfirmModalBetUnlock = () => setShowConfirmModal(false);
  const handleOpenConfirmModalBetUnlock = () => setShowConfirmModal(true);

  const handleCloseMarketSettelModal = () => setShowMarketSettelModal(false);
  const handleOpenMarketSettelModal = (type) => {
    setShowMarketSettelModal(true);
    // setSettelmentData((prev) => ({ ...prev, ...data, eventId }));
    setMarketSettlmentType(type);
  };

  const handleCloseConfirmInPlayModal = () => setShowConfrimInPlayModal(false);
  const handleOpenConfirmInPlayModal = () => setShowConfrimInPlayModal(true);
  const handleCloseConfirmModal = () => setShowConfirmModal(false);
  const handleOpenConfirmModal = () => setShowConfirmModal(true);

  const handleCloseToast = () => setShowToast(false);
  const handleShowToast = () => setShowToast(true);

  const [toastMessage, setToastMessage] = useState("");
  const [allRunnerData, setAllRunnerData] = useState([]);

  const handleCloseAddRunnerModal = () => {
    resetAddRunnerForm();
    setAddRunnerModal(false);
  };
  const handleCloseEditRunnerModal = () => setShowEditRunnerModal(false);
  const handleOpenAddRunnerModal = () => setAddRunnerModal(true);

  const handleOpenEditRunnerModal = (data) => {
    // setEditRunnerData((prevState) => ({
    //   ...prevState,
    //   backOda1: id.backOd.a1,
    //   backOda2: id.backOd.a2,
    //   backOda3: id.backOd.a3,
    //   layOda1: id.layOd.a1,
    //   layOda2: id.layOd.a2,
    //   layOda3: id.layOd.a3,
    //   jokeyName: id.jokeyName,
    //   horseName: id.horseName,
    // }));
    handleOpenAddRunnerModal();
    setIsEditMode(true);
    const {
      back1,
      back2,
      back3,
      lay1,
      lay2,
      lay3,
      jokeyName,
      jokeyNumber,
      horseName,
      id,
    } = data;
    setEditRunnerData({
      back1: back1 || 0,
      back2: back2 || 0,
      back3: back3 || 0,
      lay1: lay1 || 0,
      lay2: lay2 || 0,
      lay3: lay3 || 0,
      jokeyName,
      jokeyNumber,
      horseName,
      eventId: id,
    });
  };

  const handleSwitchChange = (e, data, type) => {
    debugger;
    console.log(data, "checkData");
    //setIsBetLockStatus(false);
    //setIsMatchBetLockStatus(false);
  };

  const onSubmitRunnerAddHandler = async () => {
    let evntId = eventId;
    const {
      back1,
      back2,
      back3,
      lay1,
      lay2,
      lay3,
      jokeyName,
      jokeyNumber,
      horseName,
    } = editRunnerData;
    const payload = {
      back1: back1 || 0,
      back2: back2 || 0,
      back3: back3 || 0,
      childEventId: eventId,
      createdBy: userData.username,
      horseName,
      jokeyName,
      jokeyNumber,
      lay1: lay1 || 0,
      lay2: lay2 || 0,
      lay3: lay3 || 0,
      marketId: marketDataOfEvent.id,
    };
    if (isEditMode) {
      delete payload.marketId;
      delete payload.createdBy;
      delete payload.childEventId;
      payload.updatedBy = userData.username;
      evntId = editRunnerData.eventId;
      const res = await updateRunnerHAC(evntId, payload);
      if (res.code === 200) {
        handleShowToast();

        //setErroreMessage("Invalid input!");

        //return false;
        handleCloseAddRunnerModal();
        getChildEventByIdHACHandler();
        resetAddRunnerForm();
      }
      return;
    }
    const res = await addRunnerHAC(payload);
    console.log(res);
    if (res.code === 200) {
      handleShowToast();
      toast.success("Runner added successfully!");
      //setErroreMessage("Invalid input!");

      //return false;
      handleCloseAddRunnerModal();
      getChildEventByIdHACHandler();
    }
    resetAddRunnerForm();
  };

  const resetAddRunnerForm = () => {
    setEditRunnerData({
      back1: 0,
      back2: 0,
      back3: 0,
      lay1: 0,
      lay2: 0,
      lay3: 0,
      horseName: "",
      jokeyName: "",
      jokeyNumber: "",
    });
  };

  const onSubmitBetHandler = async () => {
    let i = 1;
    if ((i = 1)) {
      setErroreMessage("Invalid input!");
      return;
    } else {
      setErroreMessage("");
    }
    let res;
    if (res.code === 200) {
      handleShowToast();
      setToastMessage("Action updated successfully!");
      handleCloseEditRunnerModal();
    }
    console.log(res, "resStatus");
  };

  const handleAddRunner = (e) => {
    const { name, value } = e.target;
    setErroreMessage("");
    setEditRunnerData((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handleMarketEditChange = (e) => {
    const { name, value } = e.target;
    setErroreMessage("");
    setEditRunnerData((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const getAllRunner = async () => {
    //const res = await getAllRunner();
    let code = 200;
    if (code === 200) {
      //   onSubmitUserHandler(res.response);
      setAllRunnerData(horse_json_data);
      //setSportData(res.response);
    }
  };

  useEffect(() => {
    getAllRunner();
  }, []);

  const getChildEventByIdHACHandler = useCallback(async () => {
    const res = await getChildEventByIdHAC(eventId);
    console.log(res, "resData");
    if (res.code === 200) {
      const { markets } = res.response;
      setHACChildData(res.response);
      console.log(markets, "markets");
      setMarketDataOfEvent(
        markets.find((market) => market.marketName === "Match Odds")
      );
    }
  }, [eventId]);

  useEffect(() => {
    getChildEventByIdHACHandler();
  }, [getChildEventByIdHACHandler]);

  const validateRunnerHACHandler = useCallback(async () => {
    const { jokeyName, jokeyNumber, horseName } = editRunnerData;
    const res = await validateRunnerHAC(
      eventId,
      horseName,
      jokeyName,
      jokeyNumber
    );
    console.log(res, "resCheck");
    if (res.code === 200) {
      if (res.response.available) {
        setMessage("Runner already exists");
        handleOpenConfirmModal();
      }
    }
  }, [editRunnerData, eventId]);

  useEffect(() => {
    const { jokeyName, jokeyNumber, horseName } = editRunnerData;
    if (jokeyName && jokeyNumber && horseName && !isEditMode) {
      validateRunnerHACHandler();
    }
  }, [editRunnerData, validateRunnerHACHandler, isEditMode]);

  const handleInPlaySwitchChange = async (e) => {
    setIsInPlay(e.target.checked);
    handleOpenConfirmInPlayModal();
  };

  const childEventInplayHACHandler = async () => {
    const res = await childEventInplayHAC(eventId, isInPlay);
    if (res.code === 200) {
      handleCloseConfirmInPlayModal();
      getChildEventByIdHACHandler();
    }

    console.log(res);
  };

  const handleChangeSettel = (e) => {
    const { name, value } = e.target;
    setSettelmentData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangeWinner = (e) => {
    const { value } = e.target;
    console.log(value);
    if (value === "abandoned" || value === "tie") {
      return setMatchResult(value);
    } else {
      setMatchResult("");
    }
    const winnerSelectionId = value.split("_")[0];
    const winnerName = value.split("_")[1];
    setWinnerData((prev) => ({
      ...prev,
      winnerSelectionId,
      winnerName,
    }));
  };

  const onSubmitSettleHanlder = async () => {
    const payload = {
      eventId: eventId,
      marketId: marketDataOfEvent?.id,
      marketName: marketDataOfEvent?.marketName,
      settlementComment: settelmentData.comment,
      winnerName: winnerData.winnerName,
      winnerSelectionId: winnerData.winnerSelectionId,
      eventType: marketSettlmentType,
    };
    if (!isEmpty(matchRestult)) {
      payload[matchRestult] = true;
      delete payload.winnerName;
    }
    if (marketSettlmentType === "rollback") {
      payload.abandoned = false;
      payload.tie = false;
    }
    const res = await settleMarketHAC(payload);
    console.log(res, "resData");

    getChildEventByIdHACHandler();
    handleCloseMarketSettelModal();
    handleShowToast();
    setToastMessage("Settle successfully!");
  };

  const handleSelectChange = (e) => {
    const { value } = e.target;
    setSelectedSelectValue(value);
    console.log(value.split("_"), "selectedValue");
    setSelectedValues({
      eventId: value.split("_")[0],
      horseName: value.split("_")[1].split("-")[0],
      jokeyName: value.split("_")[1].split("-")[1],
      jokeyNumber: value.split("_")[1].split("-")[2],
    });
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setSelectedInputValue(value);
    console.log(value.split("*"), "value");
    setSelectedValues((prev) => ({
      ...prev,
      back1: value.split("*")[0],
      back2: value.split("*")[0],
      back3: value.split("*")[0],
      lay1: value.split("*")[1],
      lay2: value.split("*")[1],
      lay3: value.split("*")[1],
    }));
    setIsInvalidValue(false);
  };

  const updateRunnerOnBlur = async () => {
    const {
      eventId,
      horseName,
      jokeyName,
      jokeyNumber,
      back1,
      back2,
      back3,
      lay1,
      lay2,
      lay3,
    } = selectedValues;
    const payload = {
      back1: back1 || 0,
      back2: back2 || 0,
      back3: back3 || 0,
      horseName,
      jokeyName,
      jokeyNumber,
      lay1: lay1 || 0,
      lay2: lay2 || 0,
      lay3: lay3 || 0,
      updatedBy: userData.username,
    };

    console.log(horseName, "horseName");
    if (isEmpty(horseName)) {
      return;
    }
    if (isEmpty(lay1)) {
      return setIsInvalidValue(true);
    }

    const res = await updateRunnerHAC(eventId, payload);
    if (res.code === 200) {
      getChildEventByIdHACHandler();
      setSelectedSelectValue("-1");
      setSelectedInputValue("");
    } else {
      setSelectedSelectValue("-1");
      setSelectedInputValue("");
    }
  };

  console.log(selectedValues, "selectedValues");

  const childEventBetEnablingHACHandler = async () => {
    const res = await childEventBetEnablingHAC(eventId, true);
    console.log(res);
    if (res.code === 200) {
      handleCloseConfirmModalBetUnlock();
      handleShowToast();
      setToastMessage("Bet Open successfully!");
    }
  };

  return (
    <>
      <div class="page-heading d-flex justify-content-between">
        <h3>{matchName}</h3>
        <header class="mb-2">
          <Row>
            <Col>
              <div className="d-flex align-items-center w-100">
                <Form.Check
                  type="switch"
                  label={
                    HACChildData.inPlay ? "In Play Enabled" : "In Play Disabled"
                  }
                  checked={HACChildData.inPlay}
                  onChange={(e) => handleInPlaySwitchChange(e)}
                />
                {marketDataOfEvent.isMarketSettled ? (
                  <Button
                    className="ml-3"
                    variant="primary"
                    onClick={() => handleOpenMarketSettelModal("rollback")}
                    style={{ marginLeft: 15 }}
                  >
                    Rollback
                  </Button>
                ) : (
                  <Button
                    className="ml-3"
                    variant="primary"
                    onClick={() => handleOpenMarketSettelModal("settle")}
                    style={{ marginLeft: 15 }}
                  >
                    Settle
                  </Button>
                )}

                <Button
                  className="ml-3"
                  variant="primary"
                  onClick={handleOpenAddRunnerModal}
                  style={{ marginLeft: 15 }}
                >
                  Add Runner
                </Button>
              </div>
            </Col>
          </Row>
        </header>
      </div>

      <Table striped bordered variant="dark" className="tb_tt">
        <thead>
          <tr>
            <th>Horse Name</th>
            <th>Jokey Name (Number)</th>
            <th>Back OD</th>
            <th>Lay OD</th>
            <th>Bet Status</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {marketDataOfEvent?.hacMarketsSubMarketInfos?.map((market) => (
            <tr key={market.childEventId}>
              <td>{market.horseName}</td>
              <td>
                {market.jokeyName} ({market.jokeyNumber}){" "}
              </td>
              <td
                title="Edit"
                style={{ cursor: "pointer" }}
                onClick={() => handleOpenEditRunnerModal(market)}
              >
                {/* 
                market.backOd.map((b) => {
                  <span>{b}</span>
                })
              */}
                <ul>
                  <li className="back">
                    <p>Back 1</p>
                    <span>{market?.back1}</span>
                  </li>
                  <li className="back">
                    <p>Back 2</p>
                    <span>{market?.back2}</span>
                  </li>
                  <li className="back">
                    <p>Back 3</p>
                    <span>{market?.back3}</span>
                  </li>
                </ul>
              </td>

              <td
                title="Edit"
                style={{ cursor: "pointer" }}
                onClick={() => handleOpenEditRunnerModal(market)}
              >
                <ul>
                  <li className="lay">
                    <p>Lay 1</p>
                    <span>{market?.lay1}</span>
                  </li>
                  <li className="lay">
                    <p>Lay 2</p>
                    <span>{market?.lay2}</span>
                  </li>
                  <li className="lay">
                    <p>Lay 3</p>
                    <span>{market?.lay3}</span>
                  </li>
                </ul>
              </td>

              <td>
                {" "}
                <Form.Check
                  type="switch"
                  label={market.betLock ? "Bet Disabled" : "Bet Enabled"}
                  checked={market.betLock}
                  onChange={(e) =>
                    handleSwitchChange(e, market, "Market_BetStatus")
                  }
                />
              </td>
              <td>
                <Form.Check
                  type="switch"
                  label={market.enabled ? "Enable" : "Disable"}
                  onChange={(e) =>
                    handleSwitchChange(e, market, "Market_Enable")
                  }
                  checked={market.enabled}
                />
              </td>
              <td>Delete</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Row>
        <Col md="3">
          <Form.Select
            aria-label="Default select example"
            onChange={handleSelectChange}
            value={selectedSelectValue}
          >
            <option value="-1">Please select</option>
            {marketDataOfEvent?.hacMarketsSubMarketInfos?.map((item) => (
              <option
                value={`${item.id}_${item.horseName}-${item.jokeyName}-${item.jokeyNumber}`}
                key={item.id}
              >
                {item.horseName}-{item.jokeyName}({item.jokeyNumber})
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col md="3">
          <Form.Control
            type="text"
            placeholder=""
            onChange={handleChange}
            onBlur={updateRunnerOnBlur}
            className={isInvalidValue ? "error" : ""}
            value={selectedInputValue}
          />
          <Form.Text className="text-muted">
            Please follow this pattern Eg. 1*2
          </Form.Text>
        </Col>
        <Col md="3">
          <Button
            variant="primary"
            disabled={marketDataOfEvent.betLock}
            onClick={handleOpenConfirmModalBetUnlock}
          >
            Open Bet
          </Button>
        </Col>
      </Row>

      <CustomModal
        show={addRunnerModal}
        handleClose={handleCloseAddRunnerModal}
        onSubmit={onSubmitRunnerAddHandler}
        title={isEditMode ? "Edit Runner" : "Add Runner"}
        isError={errorMessage}
      >
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Horse Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Horse Name"
              name="horseName"
              value={editRunnerData?.horseName}
              onChange={handleAddRunner}
              readOnly={isEditMode}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Jokey Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Jokey Name"
              name="jokeyName"
              value={editRunnerData?.jokeyName}
              onChange={handleAddRunner}
              readOnly={isEditMode}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Jokey Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Jokey Number"
              name="jokeyNumber"
              value={editRunnerData?.jokeyNumber}
              onChange={handleAddRunner}
              readOnly={isEditMode}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Back</Form.Label>
            <Row>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="back 1"
                  name="back1"
                  value={editRunnerData?.back1}
                  onChange={handleAddRunner}
                />
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="back 2"
                  name="back2"
                  value={editRunnerData?.back2}
                  onChange={handleAddRunner}
                />
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="back 3"
                  name="back3"
                  value={editRunnerData?.back3}
                  onChange={handleAddRunner}
                />
              </Col>
            </Row>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Lay</Form.Label>
            <Row>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="lay 1"
                  name="lay1"
                  value={editRunnerData?.lay1}
                  onChange={handleAddRunner}
                />
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="lay 2"
                  name="lay2"
                  value={editRunnerData?.lay2}
                  onChange={handleAddRunner}
                />
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="lay 3"
                  name="lay3"
                  value={editRunnerData?.lay3}
                  onChange={handleAddRunner}
                />
              </Col>
            </Row>
          </Form.Group>
        </Form>
        {errorMessage && <p className="text-danger">{errorMessage}</p>}
      </CustomModal>

      <ToastMessage
        show={showToast}
        close={handleCloseToast}
        message={toastMessage}
        type="Success"
        title=""
      />
      <ConfirmModal
        show={showConfrimModal}
        handleClose={handleCloseConfirmModal}
        handleConfirm={handleCloseConfirmModal}
      >
        <h5>{message}</h5>
      </ConfirmModal>
      <ConfirmModal
        show={showConfrimInPlayModal}
        handleClose={handleCloseConfirmInPlayModal}
        handleConfirm={childEventInplayHACHandler}
      >
        <h5>Do you want to enable in play?</h5>
      </ConfirmModal>

      <CustomModal
        show={showMarketSettelModal}
        handleClose={handleCloseMarketSettelModal}
        onSubmit={onSubmitSettleHanlder}
        title="Market Settelment"
        isError={errorMessage}
        settelmentData={settelmentData}
        fromSettel
        isRollback={marketSettlmentType === "rollback"}
      >
        {marketSettlmentType !== "rollback" ? (
          <Form>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <FloatingLabel controlId="floatingSelect" label="Select Winner">
                <Form.Select
                  aria-label="Floating label select example"
                  onChange={handleChangeWinner}
                >
                  <option>Please select a winner</option>
                  {marketDataOfEvent?.hacMarketsSubMarketInfos?.map((item) => (
                    <option
                      value={`${item.id}_${item.horseName}-${item.jokeyName}(${item.jokeyNumber})`}
                      key={item.id}
                    >
                      {item.horseName}-{item.jokeyName}({item.jokeyNumber})
                    </option>
                  ))}
                  <option value="abandoned">Abandoned</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Comment</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter comment"
                name="comment"
                value={settelmentData?.comment}
                onChange={handleChangeSettel}
              />
            </Form.Group>
            {errorMessage && <p className="text-danger">{errorMessage}</p>}
          </Form>
        ) : (
          <h5>Are you sure you want to rollback</h5>
        )}
      </CustomModal>
      <ConfirmModal
        show={showConfrimModal}
        handleClose={handleCloseConfirmModalBetUnlock}
        handleConfirm={childEventBetEnablingHACHandler}
      >
        <h5>Are you sure you want to open bet?</h5>
      </ConfirmModal>
    </>
  );
}
